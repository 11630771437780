import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { first, Observable } from 'rxjs';
import { ColorType } from '@core/components/buttons/button.component';

enum LoaderSize {
  xs = 'h-4 w-4 border-2',
  sm = 'h-6 w-6 border-4',
  md = 'h-12 w-12 border-4',
  lg = 'h-16 w-16 border-4',
  xl = 'h-32 w-32 border-4',
  auto = 'h-auto w-auto border-4',
}

@Component({
  selector: 'pd-loader',
  standalone: true,
  imports: [CommonModule],
  styles: [' :host { align-self: center; justify-self: center; text-align: center; }'],
  template: ` <div
    *ngIf="showLoader"
    [ngClass]="[diameter, loaderColorClass]"
    class="inline-block animate-spin rounded-full border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
    role="status">
    <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
      >Loading...</span
    >
  </div>`,
})
export class LoaderComponent implements OnChanges {
  @Input()
  public loading: boolean | Observable<any> = true;

  @Input()
  public color: ColorType = 'primary';

  /**
   * Diameter size of the loader in pixels:
   * xs = 16, sm = 24, md = 48, lg = 64, xl = 128
   */
  @Input()
  public size: keyof typeof LoaderSize = 'md';

  @Input()
  public display: 'inline' | 'inline-block' | 'flex' = 'flex';

  @HostBinding('class')
  public classes: string = '';

  public loaderClasses: string = '';
  public loaderColorClass: string = '';
  public showLoader: boolean = false;

  public get diameter(): string {
    return LoaderSize[this.size];
  }

  public constructor() {}

  public ngOnChanges(): void {
    this.loaderClasses = this.getLoaderClassesByDisplay(this.display);
    this.determineLoaderState();
    this.loaderColorClass = this.getLoaderColor(this.color);
  }

  public getLoaderColor(color: ColorType): string {
    switch (color) {
      case 'primary':
        return 'text-primary';
      case 'secondary':
        return 'text-secondary';
      case 'success':
        return 'text-green-500';
      case 'warning':
        return 'text-yellow-500';
      default:
        return '';
    }
  }

  public getLoaderClassesByDisplay(display: string): string {
    if (display === 'inline-block') {
      return 'align-middle';
    } else {
      return 'mx-auto text-center';
    }
  }

  private determineLoaderState(): void {
    if (this.loading instanceof Observable) {
      this.showLoader = true;
      this.loading.pipe(first()).subscribe({
        next: () => {
          this.showLoader = false;
        },
        error: () => {
          this.showLoader = false;
        },
      });
    } else {
      this.showLoader = this.loading;
    }
  }
}
